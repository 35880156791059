<script>
import { defineComponent } from 'vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { DHME_WKB_RISK_ANALYSIS } from '@/modules/modules';
import { mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import AntLoading from '@/components/AntLoading.vue';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import AntInput from '@/components/AntInput.vue';
import FileHandlerService from '@/services/file-handler';

export default defineComponent({
  name: 'DHMEWKBRiskAnalysis',
  components: {
    AntInput,
    PanelResizable,
    AntLoading,
    DynamicDataTable,
    ModuleNavigationBar,
  },
  data: () => {
    return {
      iframeUrl: null,
      iframeDialog: false,
      selectedSubchapter: null,
      substantiationsHeaders: [
        {
          text: 'Beschrijving',
          value: 'description',
        },
        {
          text: 'Gebruiker',
          value: 'user',
        },
        {
          text: 'Documenten',
          value: 'documents',
          hasSlot: true,
        },
        {
          text: '',
          value: 'upload',
          hasSlot: true,
          align: 'right',
        },
      ],
      substantiationDialog: false,
      substantiationItem: {},
      selectedSubstantiation: null,
      documentHeaders: [
        {
          text: 'name',
          value: 'name',
          hasSlot: true,
          align: 'center',
        },
      ],
      documentDialog: false,
      documentItem: null,
      riskOptions: [
        {
          text: '0-afgehandeld',
          saved: 'afgehandeld',
          value: 0,
        },
        {
          text: '1-gering',
          saved: 'gering',
          value: 1,
        },
        {
          text: '2-mogelijk',
          saved: 'mogelijk',
          value: 2,
        },
        {
          text: '3-risico',
          saved: 'risico',
          value: 3,
        },
        {
          text: '4-n.v.t.',
          saved: 'n.v.t.',
          value: 4,
        },
      ],
      toggleChapterFilter: false,
      revisionLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'wkbRiskAnalysisChapters',
      'wkbRiskAnalysisDataStatus',
      'wkbRiskAnalysisChapterStatuses',
      'wkbRiskAnalysisChapterSubstantiations',
      'wkbRiskAnalysisChapterSubstantiationDocuments',
      'wkbRiskAnalysisChapterSubstantiationDocumentsTableId',
      'authenticatedUser',
      'wkbRiskAnalysisRevisions',
      'isDaiwaLicense',
    ]),
    latestRevision() {
      return this.wkbRiskAnalysisRevisions[0]?.reason.split('_') ?? [];
    },

    headChapters() {
      let chapters = this.wkbRiskAnalysisChapters
        .filter((item) => !item.sub_chapter)
        .sort((a, b) => a.chapter - b.chapter);

      if (this.toggleChapterFilter) {
        chapters = chapters.filter(
          (chapter) => this.getSubChapters(chapter).length > 0
        );
      }

      return chapters;
    },

    chapterSubstantiations() {
      return this.wkbRiskAnalysisChapterSubstantiations.filter(
        (record) => record.chapter === this.selectedSubchapter.id
      );
    },
  },
  mounted() {
    this.$store.dispatch('fetchWkbRiskAnalysisModuleData', {
      projectId: this.project.id,
      moduleId: this.project.modules.find(
        (module) => module.route === DHME_WKB_RISK_ANALYSIS
      ).id,
      sessionId: this.$route.params.sessionId ?? null,
    });
  },
  methods: {
    async createRevision() {
      this.revisionLoading = true;
      await this.$store.dispatch('createWkbRiskAnalysisRevision');
      this.revisionLoading = false;
    },
    openChapter() {
      this.iframeUrl = `https://www.bblonline.nl/`;
      this.iframeDialog = true;
    },
    getSubChapters(headChapter) {
      let chapters = this.wkbRiskAnalysisChapters
        .filter(
          (item) => item.chapter === headChapter.chapter && item.sub_chapter
        )
        .sort((a, b) => a.sub_chapter - b.sub_chapter);

      if (this.toggleChapterFilter) {
        chapters = chapters.filter((chapter) => {
          return this.getSubChapterState(chapter.chapter, chapter.sub_chapter)
            ? this.getSubChapterState(chapter.chapter, chapter.sub_chapter) !==
                'n.v.t.'
            : false;
        });
      }

      return chapters;
    },
    selectSubChapter(subchapter) {
      this.selectedSubchapter = subchapter;
    },
    getSubChapterState(chapter, subchapter) {
      return this.wkbRiskAnalysisChapterStatuses.find(
        (record) => record.chapter === `${chapter}.${subchapter}`
      )?.status;
    },
    getSubChapterStateColor(type) {
      switch (type) {
        case 'afgehandeld':
          return '#66bb6a';
        case 'gering':
          return '#d8d827';
        case 'mogelijk':
          return '#ffc107';
        case 'risico':
          return '#ef5350';
        case 'n.v.t.':
          return '#424242';
        default:
          return '#7d7d7d';
      }
    },
    getSubstantiationDocuments(substantiationId) {
      return this.wkbRiskAnalysisChapterSubstantiationDocuments.filter(
        (record) => record.substantiation === substantiationId
      );
    },
    closeDocumentDialog() {
      this.documentDialog = false;
      this.documentItem = {};
    },
    closeSubstantiationDialog() {
      this.substantiationDialog = false;
      this.substantiationItem = {};
    },
    saveSubstantiation() {
      let body = {
        chapter: this.selectedSubchapter.id,
        type: 'toelichting',
        description: this.substantiationItem.description,
        user: this.authenticatedUser.email,
      };

      try {
        this.$store.dispatch('createChapterSubstantiation', body);
        this.closeSubstantiationDialog();
      } catch (e) {}
    },
    async saveDocument() {
      let body = {
        substantiation: this.selectedSubstantiation.id,
        file: null,
      };
      await FileHandlerService.handleFile(this.documentItem).then((value) => {
        body.file = value;
      });

      try {
        await this.$store.dispatch('uploadSubstantiationDocument', body);
        this.closeDocumentDialog();
      } catch (e) {}
    },
    downloadDocument(item) {
      FileHandlerService.downloadRecordDocument(
        item.file.id,
        this.project.id,
        this.wkbRiskAnalysisChapterSubstantiationDocumentsTableId
      );
    },
    removeDocument(item) {
      this.$store.dispatch('removeSubstantiationDocument', item);
    },
    updateChapterStatus(value) {
      const record = this.wkbRiskAnalysisChapterStatuses.find(
        (record) =>
          record.chapter ===
          `${this.selectedSubchapter.chapter}.${this.selectedSubchapter.sub_chapter}`
      );
      let body = {
        chapter: `${this.selectedSubchapter.chapter}.${this.selectedSubchapter.sub_chapter}`,
        status: value,
      };
      if (record) {
        this.$store.dispatch('updateChapterStatus', {
          id: record.id,
          data: body,
        });
      } else {
        this.$store.dispatch('createChapterStatus', {
          data: body,
        });
      }
    },
    getStatusColor(level) {
      switch (level) {
        case 0:
          return '#66bb6a';
        case 1:
          return '#d8d827';
        case 2:
          return '#ffc107';
        case 3:
          return '#ef5350';
        case 4:
          return '#424242';
        default:
          return '#424242';
      }
    },
  },
});
</script>

<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Risico Analyse">
      <template #module-nav-logo>
        <img
          v-if="isDaiwaLicense"
          alt=""
          height="40px"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
        />
      </template>
    </module-navigation-bar>
    <div
      v-if="wkbRiskAnalysisDataStatus === 'success'"
      class="flex-1 d-flex"
      style="height: calc(100vh - 46px - 46px)"
    >
      <panel-resizable
        :collapsible="false"
        :default-width="500"
        :min-width="300"
        side="left"
      >
        <div class="flex-1 d-flex flex-column">
          <v-dialog
            v-model="iframeDialog"
            max-width="1200"
            @click:outside="
              iframeUrl = null;
              iframeDialog = false;
            "
          >
            <div
              class="ant-glass-background overflow-hidden"
              style="width: 1200px"
            >
              <iframe :src="iframeUrl" height="600" width="100%" />
            </div>
          </v-dialog>
          <div class="d-flex align-center">
            <v-spacer />
            <v-tooltip left>
              <template #activator="{ on }">
                <v-btn icon @click="openChapter()" v-on="on">
                  <v-icon> mdi-information-outline</v-icon>
                </v-btn>
              </template>
              <span>Ga naar BBL online</span>
            </v-tooltip>
            <v-tooltip left>
              <template #activator="{ on }">
                <v-btn
                  icon
                  @click="toggleChapterFilter = !toggleChapterFilter"
                  v-on="on"
                >
                  <v-icon :color="toggleChapterFilter ? 'primary' : ''"
                    >{{
                      toggleChapterFilter
                        ? 'mdi-image-filter-center-focus-strong'
                        : 'mdi-image-filter-center-focus-strong-outline'
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{
                toggleChapterFilter
                  ? 'Laat alle hoofdstukken zien'
                  : 'Laat alle hoofdstukken zien met risico level'
              }}</span>
            </v-tooltip>
          </div>
          <div
            class="overflow-y-auto"
            style="height: calc(100vh - 46px - 46px - 36px)"
          >
            <div
              v-for="headChapterItem in headChapters"
              :key="headChapterItem.id"
              class="px-1 py-1"
            >
              <v-subheader
                class="analysis-chapter"
                style="height: auto; font-size: 16px"
              >
                Hoofdstuk {{ headChapterItem.chapter }}.
                {{ headChapterItem.title }}
                <v-spacer />
              </v-subheader>
              <div class="pl-3 py-2">
                <div
                  v-for="subChapterItem in getSubChapters(headChapterItem)"
                  :key="subChapterItem.id"
                  :class="{ active: subChapterItem === selectedSubchapter }"
                  class="sub-chapter"
                  @click="selectSubChapter(subChapterItem)"
                >
                  <v-subheader class="analysis-chapter" style="height: auto">
                    <v-tooltip right>
                      <template #activator="{ on, attrs }">
                        <v-icon
                          :color="
                            getSubChapterStateColor(
                              getSubChapterState(
                                subChapterItem.chapter,
                                subChapterItem.sub_chapter
                              )
                            )
                          "
                          class="mx-2"
                          dense
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{
                            getSubChapterState(
                              subChapterItem.chapter,
                              subChapterItem.sub_chapter
                            )
                              ? 'mdi-circle'
                              : 'mdi-circle-outline'
                          }}
                        </v-icon>
                      </template>
                      <span>{{
                        getSubChapterState(
                          subChapterItem.chapter,
                          subChapterItem.sub_chapter
                        )
                          ? getSubChapterState(
                              subChapterItem.chapter,
                              subChapterItem.sub_chapter
                            )
                          : 'Status unknown'
                      }}</span>
                    </v-tooltip>

                    {{ subChapterItem.chapter }}.{{
                      subChapterItem.sub_chapter
                    }}:
                    {{ subChapterItem.title }}
                    <v-spacer />
                  </v-subheader>
                </div>
              </div>
              <v-divider />
            </div>
          </div>
        </div>
      </panel-resizable>
      <div v-if="selectedSubchapter" class="flex-1 d-flex flex-column">
        <div
          :style="{
            'background-color': getSubChapterStateColor(
              getSubChapterState(
                selectedSubchapter.chapter,
                selectedSubchapter.sub_chapter
              )
            ),
          }"
          class="d-flex align-center px-5 py-2 white--text"
        >
          {{ selectedSubchapter.chapter }}.{{ selectedSubchapter.sub_chapter }}
          <v-divider class="mx-2" vertical />
          {{ selectedSubchapter.title }}
          <v-spacer />
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-chip small v-bind="attrs" v-on="on">
                {{
                  getSubChapterState(
                    selectedSubchapter.chapter,
                    selectedSubchapter.sub_chapter
                  )
                    ? getSubChapterState(
                        selectedSubchapter.chapter,
                        selectedSubchapter.sub_chapter
                      )
                    : 'Unknown'
                }}
              </v-chip>
            </template>
            <v-list dense>
              <v-list-item
                v-for="lvl in riskOptions"
                :key="lvl.value"
                @click="updateChapterStatus(lvl.saved)"
              >
                <v-chip :color="getStatusColor(lvl.value)" class="white--text"
                  >{{ lvl.text }}
                </v-chip>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <dynamic-data-table
          :table-headers="substantiationsHeaders"
          :table-records="chapterSubstantiations"
          style="background-color: white"
          table-title="Onderbouwingen"
        >
          <template #item.documents="{ value, item, rowId }">
            <v-chip
              v-for="document in getSubstantiationDocuments(item.id)"
              :key="document.id"
              class="mr-2"
              close
              small
              @click="downloadDocument(document)"
              @click:close="removeDocument(document)"
            >
              {{ document.file.name }}.{{ document.file.extension }}
            </v-chip>
          </template>
          <template #item.upload="{ value, item, rowId }">
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon
                  class="ant-icon"
                  dense
                  @click="
                    selectedSubstantiation = item;
                    documentDialog = true;
                  "
                  v-on="on"
                  >mdi-file-upload
                </v-icon>
              </template>
              Upload bestand
            </v-tooltip>
          </template>
          <template #table-actions>
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon
                  class="ant-icon"
                  @click="substantiationDialog = true"
                  v-on="on"
                  >mdi-plus
                </v-icon>
              </template>
              Voeg onderbouwing toe
            </v-tooltip>
            <v-dialog
              v-model="substantiationDialog"
              max-width="600px"
              @click:outside="closeSubstantiationDialog()"
              @keydown.esc="closeSubstantiationDialog()"
            >
              <v-card>
                <v-card-title class="justify-center text-uppercase headline">
                  Voeg nieuwe onderbouwing toe
                </v-card-title>

                <div class="px-10 pb-5">
                  <ant-input label="Description">
                    <template #input-field>
                      <v-textarea
                        v-model="substantiationItem.description"
                        dense
                        filled
                        hide-details
                        placeholder="Write here your text"
                        single-line
                      />
                    </template>
                  </ant-input>
                </div>
                <v-card-actions class="ant-dialog-actions-bg ant-border-top">
                  <v-spacer />
                  <v-btn
                    color="error"
                    small
                    text
                    @click="closeSubstantiationDialog"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    elevation="0"
                    small
                    @click="saveSubstantiation"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="documentDialog"
              max-width="600px"
              @click:outside="closeDocumentDialog()"
              @keydown.esc="closeDocumentDialog()"
            >
              <v-card>
                <v-card-title class="justify-center text-uppercase headline">
                  Upload file
                </v-card-title>

                <div class="px-10 pb-5">
                  <ant-input label="File">
                    <template #input-field>
                      <v-file-input
                        v-model="documentItem"
                        dense
                        filled
                        hide-details
                        placeholder="File"
                        single-line
                      />
                    </template>
                  </ant-input>
                </div>
                <v-card-actions class="ant-dialog-actions-bg ant-border-top">
                  <v-spacer />
                  <v-btn color="error" small text @click="closeDocumentDialog">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    elevation="0"
                    small
                    @click="saveDocument"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </dynamic-data-table>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center flex-grow-1">
      <ant-loading />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.analysis-risk-item {
  cursor: pointer;
  transition: 100ms ease;

  &.analysis-risk-item-active {
    background: #f2f2f2;
  }

  &:hover {
    background: #f2f2f2;
  }
}

.sub-chapter {
  cursor: pointer;
  transition: 200ms ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.analysis-chapter {
  .extra-info-icon {
    opacity: 0;
    pointer-events: none;
    transition: 200ms ease-out;
  }

  &:hover {
    .sub-chapter {
      cursor: pointer;
      background-color: lightgray;
    }

    .extra-info-icon {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.clickable-file {
  cursor: pointer;

  &:hover {
    color: var(--v-primary-base);
  }
}

.active-row {
  border-left: solid 4px var(--v-primary-base);
}

.chip-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 8px; /* Adjust gap between chips */
}
</style>
